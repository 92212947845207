@import '../variables.scss';
// STYLE
.contact {
    background: $background;
    padding: 10px 0 50px;
    color: $grey;
    font-size: 17px;
    .contact__inner {
        text-align: center;
        margin-top: 100px;
        .contact_red {
            cursor: pointer;
            color: $grey;
            transition: color ease-in-out .1s;
            &:hover {
                color: red;
            }
        }
    }
}
// RESPONSIVE
@media screen and (max-width: 650px) {
    .contact {
        font-size: 13px;
        .contact__inner {
            margin: 50px auto 0px;
            width: 80%;
        }
    }
}