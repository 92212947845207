@import '../variables.scss';
// STYLE
.Assets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $background;
    .overview, .technologies, .wireframe, .result, .conclusion {
        color: $grey;
        opacity: 0;
        margin-top: 20px;
        animation: technologiesFadeIn 0.5s forwards;
    }
    .txt {
        width: 800px;
    }
    .technologies {
        animation: wireframeFadeIn 0.5s forwards;
        .technologies__icones {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 50px;
                margin: 0 20px;
            }
            span {
                margin: 0 20px;
            }
        }
        .technologies__desc {
            margin-top: 20px;
        }
    }
    .wireframe {
        animation: overviewFadeIn 0.5s forwards;
        display: flex;
        flex-direction: column;
        align-items: center;
        .wireframe__image {
            width: 600px;
        }
        .wireframe__link {
            display: flex;
            a {
                color: $grey;
                margin: 20px 20px;
                transition: color ease-in-out .2s;
                &:hover {
                    color: white;
                }
            }
        }
    }
    .result {
        animation: resultFadeIn 0.5s forwards;
    }
    .conclusion {
        animation: conclusionFadeIn 0.5s forwards;
        .links {
            text-align: center;
            margin-top: 50px;
            >a {
                color: $grey;
                margin: 0 20px;
                transition: color ease-in-out .2s;
                &:hover {
                    color: white;
                }
            }
        }
    }
    .project_header__link {
        width: 100%;
        text-align: center;
        bottom: 0;
        margin-top: 40px;
        a {
            color: $grey;
            font-weight: 900;
            cursor: pointer;
            font-size: 15px;
            transition: color ease-in-out .2s;
            &:hover {
                color: white;
            }
        }
    }
}
// ANIMATIONS
@keyframes overviewFadeIn {
    100% {opacity: 1;margin: 0;}
}
@keyframes technologiesFadeIn {
    100% {opacity: 1;margin: 0;}
}
@keyframes wireframeFadeIn {
    100% {opacity: 1;margin: 0;}
}
@keyframes resultFadeIn {
    100% {opacity: 1;margin: 0;}
}
@keyframes conclusionFadeIn {
    100% {opacity: 1;margin: 0;}
}
.test {
    display: none;
}
// RESPONSIVE
@media screen and (max-width: 1000px) {
    .Assets {
        .txt {
            width: 80%;
            margin: auto;
        }
        .wireframe {
            display: flex;
            justify-content: center;
            .wireframe__image {
                width: 60%;
            }
        }
    }
}
@media screen and (max-width: 650px) {
    .Assets {
        .txt {
            font-size: 12px;
        }
        .technologies {
            .technologies__icones {
                img {
                    width: 30px;
                    margin: 0 10px;
                }
                span {
                    font-size: 15px;
                    margin: 0 10px;
                }
            }
        }
        .wireframe {
            .wireframe__image {
                width: 80%;
            }
            .wireframe__link {
                flex-direction: column;
                margin-top: 10px;
                a {
                    font-size: 12px;
                    text-align: center;
                    margin: 0;
                }
            }
        }
        .conclusion {
            animation: conclusionFadeIn 0.5s forwards;
            .links {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                >a {
                    margin: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}