@import '../variables.scss';
// STYLE
.select {
    background: $background;
    padding: 50px 0;
    color: $grey;
    font-size: 20px;
    .select__inner {
        text-align: center;
        .select__list {
            position: relative;
            margin: auto;
            &::-webkit-scrollbar {height: 0}
            display: flex;
            justify-content: center;
            &::before {
                position: absolute;
                content: '';
                display: block;
                width: 380px;
                // width: 90%;
                height: 1px;
                top: 23px;
                background: $grey;
                opacity: .2;
            }
            li {
                cursor: pointer;
                font-size: 15px;
                padding: 0 10px;
                white-space: nowrap;
                transition: color ease-in-out .1s;
                &:hover{
                    color: white;
                }
            }
            li.active {
                color: white;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    opacity: 0;
                    background: white;
                    animation: underlineFadeIn 0.5s forwards;
                }
            }
        }
    }
}
// ANIMATION 
@keyframes underlineFadeIn {
    100% {
      opacity: 1; 
    }
}
// RESPONSIVE
@media screen and (max-width: 500px) {
    .select {
        .select__inner {
            .select__list {
                width: 80%;
                justify-content: flex-start;
                overflow: scroll;
                &::before {
                    left: 10px;
                }
            }
        }
    }
}

