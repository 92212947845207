@import '../variables.scss';
// STYLE
.work {
    background: $background;
    padding: 50px 0;
    .work__inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        .work__title {
            margin-top: 150px;
            color: transparent;
            font-size: 50px;
            -webkit-text-stroke: 0.05555555555555555rem #ffffff;
        }
        .work__projects {
            margin-top: 50px;
            display: grid;
            grid-gap: 100px;
            grid-template-columns: repeat(2,1fr);
            a {
                position: relative;
                border-radius: 20px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.5);
                transition: all ease-in-out .2s;
                &:hover {
                    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0);
                }
                .work__project__img {
                    opacity: .7;
                    width: 600px;
                    transition: all ease-in-out .2s;
                    &:hover {
                        opacity: 1;
                    }
                }
                .work__project__text {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    font-size: 20px;
                    font-weight: 500;
                    margin: 20px;
                    color: white;
                    >.subtitle {
                        color: rgb(33, 150, 243);
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: 1400px) {
    .work {
        .work__inner{
            .work__projects {
                grid-gap: 50px;
                a {
                    .work__project__img {
                        width: 450px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .work {
        .work__inner{
            .work__projects {
                grid-template-columns: repeat(1,1fr);
                a {
                    .work__project__img {
                        filter: blur(0px);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 650px) {
    .work {
        .work__inner{
            margin-top: 0px;
            .work__title {
                font-size: 35px;
                text-align: center;
                margin-left: 10px;
                margin-right: 10px;
            }
            .work__projects {
                grid-gap: 30px;
                a {
                    .work__project__img {
                        width: 300px;
                    }
                    .work__project__text {
                        font-size: 15px;
                        margin: 15px;
                        >.subtitle {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .work {
        .work__inner{
            .work__projects {
                a {
                    .work__project__img {
                        width: 230px;
                    }
                    .work__project__text {
                        font-size: 12px;
                        margin: 10px;
                        >.subtitle {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}