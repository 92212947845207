@import '../variables.scss';
// STYLE
.about {
    background: $background;
    padding: 50px 0;
    .about__inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        .about__title {
            color: transparent;
            font-size: 50px;
            -webkit-text-stroke: 0.05555555555555555rem #ffffff;
        }
        .about__desc {
            margin-top: 50px;
            font-size: 20px;
            color: $grey;
            max-width: 850px;
            width: 80%;
            display: flex;
            flex-direction: column;
            .spaces {
                margin-bottom: 20px;
            }
            .white{
                color: white;
            }
            .sun{
                color: rgb(255, 153, 0);
            }
        }
    }
}
// RESPONSIVE
@media screen and (max-width: 1400px) {
    .about {
        .about__inner{
            .about__title {
                grid-gap: 50px;
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .about {
        .about__inner{
            .about__desc {
                font-size: 18px;
            }
        }
    }
}
@media screen and (max-width: 650px) {
    .about {
        .about__inner{
            margin-top: 150px;
            .about__title {
                font-size: 40px;
            }
            .about__desc {
                text-align: center;
                margin-top: 20px;
                font-size: 15px;
                max-width: 800px;
                width: 70%;
            }
        }
    }
}
@media screen and (max-width: 650px) {
    .about {
        .about__inner{
            .about__title {
                font-size: 35px;
            }
        }
    }
}